
import { defineComponent, ref, reactive } from 'vue';
// import ApplicableTypeClassSelector from '@/views/LocalRegistration/components/ApplicableTypeClassSelector.vue'
import RegionSelector from '@/views/LocalRegistration/components/RegionSelector.vue';
import SubRegionSelector from '@/views/LocalRegistration/components/SubRegionSelector.vue';
import {
    applicableTypeClassList,
    getDealerApplicableTypeClassList,
    getLocalDealerByCode,
    getLocalDealerBySwtCode,
    getLocalDealerByName,
    getAllProvince,
    getAllCitys,
    getCityListByProvinceId,
    searchDealerListByCondition,
    exportDealerListByCondition,
} from '@/API/localRegistration';
import { debounce } from 'lodash-es';
import moment from 'moment';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { elementSize } from '@/utils';
import { downloadFromStream } from '@/utils/payment/downloadFile';

export default defineComponent({
    components: {
        // ApplicableTypeClassSelector,
        // RegionSelector,
        // SubRegionSelector
    },
    setup(props) {
        //分页组件
        const pagination = reactive({
            pageSize: 20,
            currentPage: 1,
            total: 0,
        });
        const store = useStore();
        const currentUserBu: string = store.state.user.bu.orgnmen;
        const buList = store.state.user.buList
            .filter((item: any) => !item.parentId)
            .map((item: any) => item.nameEn);
        const router: any = useRouter();
        const columns = [
            {
                title: 'Dealer/SWT Code',
                dataIndex: 'dealerCode',
                key: 'dealerCode',
            },
            {
                title: 'Dealer Name',
                dataIndex: 'dealerName',
                key: 'dealerName',
            },
            {
                title: 'Province',
                dataIndex: 'province',
                key: 'province',
            },
            {
                title: 'Part I Area',
                dataIndex: 'city',
                key: 'city',
            },
            {
                title: 'Province II',
                dataIndex: 'provinces',
                key: 'provinces',
                slots: { customRender: 'provinces' },
            },
            {
                title: 'Part II Area',
                dataIndex: 'citys',
                key: 'citys',
                slots: { customRender: 'PartIIArea' },
            },
            {
                title: 'Part II Area No.',
                dataIndex: 'applicableVO.cityCount',
                key: 'applicableVO.cityCount',
            },
            {
                title: 'Region',
                dataIndex: 'region',
                key: 'region',
            },
            {
                title: 'Subregion',
                dataIndex: 'subRegion',
                key: 'subRegion',
            },
            {
                title: 'dealer Group',
                dataIndex: 'dealerGroup',
                key: 'dealerGroup',
            },
            {
                title: 'Special Area',
                dataIndex: 'specialArea',
                key: 'specialArea',
                slots: { customRender: 'specialArea' },
            },
            {
                title: 'Applicable Type Class',
                dataIndex: 'applicableTypeClass',
                key: 'applicableTypeClass',
            },
            {
                title: 'Effective Time',
                dataIndex: 'effectiveTime',
                key: 'effectiveTime',
            },
            {
                title: 'Operation',
                align: 'center',
                slots: { customRender: 'Operation' },
            },
        ];

        const data = ref([]);

        const searchCondition: any = reactive({
            applicableTypeClass: [],
            exportTime: moment(new Date()).format('YYYY-MM-DD'),
            dealerCode: '',
            swtCode: '',
            dealerName: '',
            province: '',
            city: '',
            region: '',
            subRegion: '',
            dealerGroup: '',
            // specialCity: []
        });

        const specialCityOptions = ref<any[]>([]);

        const typeClassList = ref<any[]>([]);
        const dealerCodeOptions = ref<string[]>([]);
        const swtCodeOptions = ref<string[]>([]);
        const dealerNameOptions = ref<string[]>([]);
        const provinceOptions = ref<any[]>([]);
        const cityOptions = ref<any[]>([]);
        const fetching = ref<boolean>(false);

        const tableSize = elementSize('.data-table-wrapper');

        const handleGetDealerCodeOptions = debounce((value) => {
            dealerCodeOptions.value = [];
            fetching.value = true;
            const params = {
                dealerCode: value,
            };
            getLocalDealerByCode(params).then((res: any) => {
                dealerCodeOptions.value = res.data.map((item: any) => {
                    return {
                        label: item.dealerCode,
                        value: item.dealerCode,
                        city: item.city,
                        dealerName: item.dealerName,
                        dealerCode: item.dealerCode,
                        province: item.province,
                        swtCode: item.swtCode,
                        dealerGroup: item.dealerGroup,
                        dealerId: item.id,
                    };
                });
            });
        }, 300);

        const handleGetSwtCodeOptions = debounce((value) => {
            swtCodeOptions.value = [];
            fetching.value = true;
            const params = {
                swtCode: value,
            };
            getLocalDealerBySwtCode(params).then((res: any) => {
                swtCodeOptions.value = res.data.map((item: any) => {
                    return {
                        label: item.swtCode,
                        value: item.swtCode,
                        city: item.city,
                        dealerName: item.dealerName,
                        dealerCode: item.dealerCode,
                        province: item.province,
                        swtCode: item.swtCode,
                        dealerGroup: item.dealerGroup,
                        dealerId: item.id,
                    };
                });
            });
        }, 300);

        const handleGetDealerOptions = debounce((value) => {
            dealerNameOptions.value = [];
            fetching.value = true;
            const params = {
                dealerName: value,
            };
            getLocalDealerByName(params).then((res: any) => {
                dealerNameOptions.value = res.data.map((item: any) => {
                    return {
                        label: item.dealerName,
                        value: item.dealerName,
                        city: item.city,
                        dealerCode: item.dealerCode,
                        dealerName: item.dealerName,
                        province: item.province,
                        swtCode: item.swtCode,
                        dealerGroup: item.dealerGroup,
                        dealerId: item.id,
                    };
                });
            });
        }, 300);

        const handleProvinceChange = (value: any) => {
            const province = provinceOptions.value.filter(
                (item) => item.name === value
            )[0];
            const id = province.id;
            const params = {
                provinceId: id,
            };
            searchCondition.city = '';
            getCityListByProvinceId(params).then((res: any) => {
                cityOptions.value = res.data;
            });
        };

        const handleReset = () => {
            Object.assign(searchCondition, {
                applicableTypeClass: [],
                exportTime: moment(new Date()).format('YYYY-MM-DD'),
                dealerCode: '',
                swtCode: '',
                dealerName: '',
                province: '',
                city: '',
                region: '',
                subRegion: '',
                dealerGroup: '',
                // specialCity: []
            });
        };

        const handleSearch = () => {
            const params = {
                ...searchCondition,
                num: pagination.currentPage,
                size: pagination.pageSize,
            };
            if (params.applicableTypeClass.length === 0) {
                params.applicableTypeClass = typeClassList.value;
            }
            params.exportTime =
                moment(params.exportTime).format('YYYY-MM-DD') ===
                'Invalid date'
                    ? '1990-01-01'
                    : moment(params.exportTime).format('YYYY-MM-DD');
            searchDealerListByCondition(params).then((res: any) => {
                data.value = res.data;
                pagination.total = res.total;
            });
        };

        const handleEdit = (record: any) => {
            const query = {
                id: record.id || 4,
            };
            router.push({
                path: '/localRegistration/focusAreaSet/dealerAdjustment/edit',
                query,
            });
        };

        const filterOption = (input: any, option: any) => {
            return option.children[0].children.indexOf(input) >= 0;
        };

        const handleExport = async () => {
            const params = {
                ...searchCondition,
            };
            if (params.applicableTypeClass.length === 0) {
                params.applicableTypeClass = typeClassList.value;
            }
            params.exportTime =
                moment(params.exportTime).format('YYYY-MM-DD') ===
                'Invalid date'
                    ? '1990-01-01'
                    : moment(params.exportTime).format('YYYY-MM-DD');
            const res = await exportDealerListByCondition(params);
            downloadFromStream(res);
        };

        const init = () => {
            // applicableTypeClassList(getDealerApplicableTypeClassList,).then((res: any) => {
            //   typeClassList.value = res
            //   searchCondition.applicableTypeClass = res
            // })
            getDealerApplicableTypeClassList(buList).then((res: any) => {
                typeClassList.value = res;
                searchCondition.applicableTypeClass = res;
            });
            getAllProvince().then((res: any) => {
                provinceOptions.value = res;
            });
            getAllCitys().then((res: any) => {
                specialCityOptions.value = res;
            });
        };
        init();

        const pageChange = (page: number) => {
            pagination.currentPage = page;
            handleSearch();
        };
        const sizeChange = (page: number, pageSize: number) => {
            console.log(page, 'sizeChange');
            pagination.currentPage = page + 1;
            pagination.pageSize = pageSize;
            handleSearch();
        };

        return {
            tableSize,
            fetching,
            currentUserBu,
            typeClassList,
            dealerCodeOptions,
            dealerNameOptions,
            specialCityOptions,
            provinceOptions,
            cityOptions,
            searchCondition,
            columns,
            data,
            handleGetDealerCodeOptions,
            handleGetDealerOptions,
            swtCodeOptions,
            handleGetSwtCodeOptions,
            handleProvinceChange,
            handleReset,
            handleSearch,
            handleEdit,
            filterOption,
            handleExport,
            pageChange,
            sizeChange,
            pagination,
        };
    },
});
